import { useTranslation } from "react-i18next";
import styles from "./Loading.module.scss";
import logo from "../../assets/images/logo.svg";

const Loading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2>{t("loading.heading")}</h2>
      <h3>{t("loading.subheading")}</h3>
      <img src={logo} alt={t("common.appName")} />
    </div>
  );
};

export default Loading;
