import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./Profile.module.scss";
import { useTranslation } from "react-i18next";
import { FormButton } from "../components";
import profileHeader from "../../assets/images/profileHeader.jpg";
import { useAuth } from "../../data/auth";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { isSignedIn, performSignOut } = useAuth();

  return (
    <div className={styles.container}>
      <img className={styles.headerImage} src={profileHeader} alt="" />

      <a
        href="https://tuckfoodtours.co.uk"
        target="_blank"
        className={styles.websiteLink}
        rel="noreferrer"
      >
        {t("profile.website")}
      </a>

      <ul className={styles.list}>
        <li>
          <a
            href="https://tuckfoodtours.co.uk/contact-us/"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.contact")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
        <li>
          <a
            href="https://tuckfoodtours.co.uk/#aboutUs"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.about")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
        <li>
          <a
            href="https://tuckfoodtours.co.uk/book-a-tour/"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.bookTour")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
        <li>
          <a
            href="https://app.websitepolicies.com/policies/view/Y7BQPAGE"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.terms")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
        <li>
          <a
            href="https://app.websitepolicies.com/policies/view/oqTJnH5K"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.privacy")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
        <li>
          <a
            href="https://tuckfoodtours.co.uk/access-our-app/"
            target="_blank"
            rel="noreferrer"
          >
            <span>{t("profile.share")}</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
      </ul>

      {isSignedIn && (
        <div className={styles.signOutButtonContainer}>
          <FormButton className={styles.signOutButton} onClick={performSignOut}>
            {t("profile.signOutButton")}
          </FormButton>
        </div>
      )}
    </div>
  );
};

export default Profile;
