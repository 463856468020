/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useState } from "react";

import { useAlert } from "react-alert";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../data/auth";
import { redeemCode, RedeemCodeResult } from "../../firebase";
import { useFormInput } from "../../hooks";
import { BackLink, FormButton, FormInput } from "../components";
import styles from "./Redeem.module.scss";

const Redeem: React.FC = () => {
  const { t } = useTranslation();
  const { tourId } = useParams<{ tourId: string }>();

  const { isSignedIn, performSignIn } = useAuth();
  const alert = useAlert();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [accessCode, onAccessCodeChange] = useFormInput();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const onFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) {
        return;
      }

      setLoading(true);

      try {
        if (!isSignedIn) {
          await performSignIn();
        }

        const response = await redeemCode(accessCode.toUpperCase());

        switch (response.result) {
          case RedeemCodeResult.Success:
            history.push(`/redeemed/${accessCode}`);
            break;
          case RedeemCodeResult.Expired:
            alert.error(t("redeem.expiredError"));
            break;
          case RedeemCodeResult.Error:
            alert.error(t("redeem.error", { message: response.error.message }));
            break;
        }
      } catch (e) {
        alert.error(t("redeem.error", { message: e.message }));
      } finally {
        setLoading(false);
      }
    },
    [accessCode, alert, history, isSignedIn, loading, performSignIn, t]
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BackLink to={`/tour/${tourId}`} className={styles.back} />

        <h2 className={styles.heading}>
          <Trans
            i18nKey="redeem.heading"
            components={[
              <span key="heading1" className={styles.heading1} />,
              <strong key="heading2" className={styles.heading2} />,
              <span key="heading3" className={styles.heading3} />,
            ]}
          />
        </h2>

        <form className={styles.form} onSubmit={onFormSubmit}>
          <FormInput
            type="text"
            className={styles.codeInput}
            placeholder={t("redeem.codeInputPlaceholder")}
            required={true}
            autoCorrect="off"
            autoCapitalize="characters"
            value={accessCode}
            onChange={onAccessCodeChange}
          />

          <div className={styles.terms}>
            <input
              type="checkbox"
              id="terms"
              required={true}
              checked={termsAccepted}
              onChange={(event) => {
                setTermsAccepted(event.target.checked);
              }}
            />

            <label htmlFor="terms">
              <Trans
                i18nKey="redeem.terms"
                components={[
                  <a
                    href="https://app.websitepolicies.com/policies/view/Y7BQPAGE"
                    target="_blank"
                    rel="noreferrer"
                    key="termsLink"
                  />,
                  <a
                    href="https://app.websitepolicies.com/policies/view/oqTJnH5K"
                    target="_blank"
                    rel="noreferrer"
                    key="privacyLink"
                  />,
                ]}
              />
            </label>
          </div>

          <FormButton
            type="submit"
            disabled={!accessCode.trim() || !termsAccepted}
            loading={loading}
          >
            {t("redeem.submitButton")}
          </FormButton>
        </form>
      </div>
    </div>
  );
};

export default Redeem;
