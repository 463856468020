import firebase from "firebase/app";
import _ from "lodash";
import { isTimestamp, WardFieldValue, WardTimestamp, WithId } from "../types";
import { firebaseFirestore } from "./core";

/**
 * Fetches the given document and updates it with the given partial. This is needed rather than the
 * update method that the SDK provides to allow it to pass the Firewald rules
 */
export const updateFirestoreDocument = <T>(
  documentRef: firebase.firestore.DocumentReference<T>,
  data: Partial<{
    [P in keyof T]: T[P] extends unknown[] | undefined
      ? T[P] | firebase.firestore.FieldValue
      : T[P];
  }>,
  defaultData?: T
) => {
  return firebaseFirestore.runTransaction(async (transaction) => {
    // Get the previous data
    const snapshot = await transaction.get(documentRef);

    // Merge the new data with the preview or detail data
    let prevData = snapshot.data();
    if (!prevData && !defaultData) {
      throw new Error(
        "Unable to update a firestore document that does not exist"
      );
    } else if (!prevData) {
      prevData = defaultData;
    }
    const newData = _.pickBy(
      { ...prevData, ...data },
      (value) => value !== null && value !== undefined
    ) as unknown as T;

    // Set the new merged data
    transaction.set(documentRef, newData);
  });
};

/**
 * Gets the data for the document with the document id included
 */
export const getDocumentWithId = async <T>(
  documentRef: firebase.firestore.DocumentReference<T>
): Promise<WithId<T> | undefined> => {
  const result = await documentRef.get();
  const data = result?.data?.();

  if (!data) {
    return undefined;
  }

  return { id: result.id, ...data };
};

/**
 * Sets up a snapshot listen which returns the document data with the document id included
 */
export const onDocumentSnapshotWithId = <T>(
  documentRef: firebase.firestore.DocumentReference<T>,
  handler: (data: WithId<T> | undefined) => void
): (() => void) => {
  return documentRef.onSnapshot((snapshot) => {
    const data = snapshot?.data?.();
    if (data) {
      handler({ id: snapshot.id, ...data });
    } else {
      handler(undefined);
    }
  });
};

/**
 * Gets the data for the document with the document id included
 */
export const getDocumentsWithId = async <T>(
  ref: firebase.firestore.CollectionReference<T> | firebase.firestore.Query<T>
): Promise<WithId<T>[]> => {
  const result = await ref.get();
  return (
    result?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) || []
  );
};

/**
 * Sets up a snapshot listen which returns the document data with the document id included
 */
export const onDocumentsSnapshotWithId = <T>(
  ref: firebase.firestore.CollectionReference<T> | firebase.firestore.Query<T>,
  handler: (data: WithId<T>[]) => void
): (() => void) => {
  return ref.onSnapshot((snapshot) => {
    const docs = snapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    handler(docs || []);
  });
};

/**
 * Converts a Fireward timestamp to a JS Date
 */
export const convertTimestampToDate = (
  value: undefined | null | Date | WardTimestamp | WardFieldValue
): Date | undefined => {
  if (value instanceof Date) {
    return value;
  } else if (isTimestamp(value)) {
    return value.toDate();
  } else {
    return undefined;
  }
};
