import React, { memo, useState } from "react";
import { NavBarContext } from "./NavBarContext";

export const NavBarProvider: React.FC<{
  children?: React.ReactNode | undefined;
}> = memo(({ children }) => {
  const [overviewButtonShown, setOverviewButtonShown] = useState(false);
  const [showTourOverview, setShowTourOverview] = useState(false);
  const [tourExpiresAt, setTourExpiresAt] = useState<Date>();

  return (
    <NavBarContext.Provider
      value={{
        overviewButtonShown,
        setOverviewButtonShown,
        showTourOverview,
        setShowTourOverview,
        tourExpiresAt,
        setTourExpiresAt,
      }}
    >
      {children}
    </NavBarContext.Provider>
  );
});
