import styles from "./ToursList.module.scss";
import logo from "../../assets/images/logo.svg";
import { toursQuery, useFirestoreQuery } from "../../firebase";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const ToursList: React.FC = () => {
  const { t } = useTranslation();

  const [tours, loaded] = useFirestoreQuery(() => toursQuery(), []);

  return (
    <div className={styles.container}>
      <h2 className={styles.welcomeHeading}>
        <Trans
          i18nKey="toursList.welcomeHeader"
          components={[<span key="first" />, <strong key="second" />]}
        />
      </h2>

      <ul className={styles.list}>
        {loaded ? (
          tours.map((tour) => (
            <li key={tour.id}>
              <Link to={`/tour/${tour.id}`}>
                <img src={tour.iconUrl || logo} alt="" />
                <h3>{tour.name}</h3>
              </Link>
            </li>
          ))
        ) : (
          <p>{t("common.loading")}</p>
        )}
      </ul>
    </div>
  );
};

export default ToursList;
