import { FirewardOutput, Tour } from "../types";
import { firebaseFirestore, firebaseFunctions } from "./core";

export const toursQuery = () => firebaseFirestore.collection<Tour>("tours");

export const tourDocumentById = (id: string) =>
  firebaseFirestore.collection<Tour<FirewardOutput>>("tours").doc(id);

export enum RedeemCodeResult {
  Success = "success",
  Expired = "expired",
  Error = "error",
}
export type RedeemCodeResponse =
  | { result: RedeemCodeResult.Success }
  | { result: RedeemCodeResult.Expired }
  | {
      result: RedeemCodeResult.Error;
      error: {
        code: string;
        message: string;
        details: { [key: string]: string };
      };
    };
const redeemCodeFunction = firebaseFunctions.httpsCallable("redeemCode");
export const redeemCode = async (
  accessCode: string
): Promise<RedeemCodeResponse> => {
  try {
    await redeemCodeFunction({ accessCode });
    return { result: RedeemCodeResult.Success };
  } catch (e) {
    if (typeof e.details === "object" && e.details.expired) {
      return { result: RedeemCodeResult.Expired };
    }

    return {
      result: RedeemCodeResult.Error,
      error: {
        code: e.code,
        message: e.message,
        details: e.details,
      },
    };
  }
};
