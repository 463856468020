import firebase from "firebase";
import React, { useCallback, useEffect, useState, memo } from "react";
import { AuthContext } from "./AuthContext";
import {
  firebaseAuth,
  tuckUserDocumentByUid,
  useFirestoreDocument,
} from "../../firebase";

export const AuthProvider: React.FC<{
  children?: React.ReactNode | undefined;
}> = memo(({ children }) => {
  /**
   * For holding the state
   */
  const [loaded, setLoaded] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<firebase.User>();
  const isSignedIn = !!firebaseUser;

  /**
   * Subscribe to authentication updates to listen for changes to the firebase user
   * */
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      setLoaded(true);
      setFirebaseUser(user || undefined);
    });

    return unsubscribe;
  }, []);

  /**
   * Subscribe to the Firestore user object whenever the Firebase user changes
   * */
  const [tuckUser] = useFirestoreDocument(() => {
    if (firebaseUser) {
      return tuckUserDocumentByUid(firebaseUser.uid);
    } else {
      return undefined;
    }
  }, [firebaseUser]);

  /**
   * Performs a quick sign in by signing the user in anonymously and then setting the email address
   */
  const performSignIn = useCallback(async () => {
    await firebaseAuth.signInAnonymously();
  }, []);

  /**
   * ign the user out
   */
  const performSignOut = useCallback(() => {
    firebaseAuth.signOut();
  }, []);

  /**
   * Return the hook values via context
   */
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        firebaseUser,
        tuckUser,
        performSignIn,
        performSignOut,
      }}
    >
      {loaded && children}
    </AuthContext.Provider>
  );
});
