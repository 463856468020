import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormButton } from "../../components";
import styles from "./StartTour.module.scss";

interface StartTourProps {
  timeLimitHours: number;
  onStartButtonPress: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

const StartTour: React.FC<StartTourProps> = ({
  timeLimitHours,
  onStartButtonPress,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.heading}>
          <Trans
            i18nKey="startTour.heading"
            components={[<span key="heading1" />, <strong key="heading2" />]}
          />
        </h2>

        <p className={styles.description}>
          {t("startTour.description1", { timeLimitHours })}
        </p>
        <p className={styles.description}>{t("startTour.description2")}</p>

        <FormButton
          type="button"
          className={styles.startButton}
          onClick={onStartButtonPress}
          loading={loading}
        >
          {t("startTour.startButton")}
        </FormButton>
      </div>
    </div>
  );
};

export default StartTour;
