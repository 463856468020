import { useCallback } from "react";
import Countdown, { CountdownRenderProps, zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import icon from "../assets/images/icon.svg";
import overviewMap from "../assets/images/overviewMap.svg";
import profile from "../assets/images/profile.svg";
import { useNavBar } from "../data/navBar";
import styles from "./NavBar.module.scss";

const NavBar: React.FC = () => {
  const { t } = useTranslation();
  const { tourExpiresAt, overviewButtonShown, setShowTourOverview } =
    useNavBar();

  const countdownRenderer = useCallback(
    ({ days, hours, minutes }: CountdownRenderProps) => {
      return (
        <p className={styles.countdown}>
          {t("navBar.countdown", {
            hours: zeroPad(days * 24 + hours),
            minutes: zeroPad(minutes),
          })}
        </p>
      );
    },
    [t]
  );

  const toggleTourOverview = useCallback(() => {
    setShowTourOverview((prev) => !prev);
  }, [setShowTourOverview]);

  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <Link to="/" className={styles.iconButton}>
          <img src={icon} alt={t("navBar.homepage")} />
        </Link>

        {overviewButtonShown && (
          <button className={styles.iconButton} onClick={toggleTourOverview}>
            <img src={overviewMap} alt={t("navBar.overviewMap")} />
          </button>
        )}

        <Link to="/profile" className={styles.iconButton}>
          <img src={profile} alt={t("navBar.profile")} />
        </Link>

        {!!tourExpiresAt && (
          <Countdown
            date={tourExpiresAt}
            renderer={countdownRenderer}
            intervalDelay={10000} // Update every 10 seconds
          />
        )}
      </div>
    </header>
  );
};

export default NavBar;
