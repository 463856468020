import addHours from "date-fns/addHours";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import isDateBefore from "date-fns/isBefore";
import { useEffect, useState, useMemo, useCallback } from "react";

import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../data/auth";
import { useNavBar } from "../../../data/navBar";
import {
  convertTimestampToDate,
  tourDocumentById,
  tourStopsQuery,
  updateFirestoreDocument,
  useFirestoreDocument,
  useFirestoreQuery,
} from "../../../firebase";
import { tourRedemptionDocument } from "../../../firebase/tourRedemptions";
import StartTour from "./StartTour";
import ViewTour from "./ViewTour";

const RedeemedTour: React.FC = () => {
  /**
   * Setup
   */

  const { t } = useTranslation();
  const alert = useAlert();
  const { setOverviewButtonShown, setTourExpiresAt, setShowTourOverview } =
    useNavBar();
  const history = useHistory();

  // Get the state from the location and auth
  const { accessCode } = useParams<{ accessCode: string }>();
  const { isSignedIn, firebaseUser } = useAuth();

  /**
   * Loading data from Firebase
   */

  // Load the tour redemption information for the user
  const tourRedemptionDoc = useMemo(() => {
    if (firebaseUser && accessCode) {
      return tourRedemptionDocument(firebaseUser.uid, accessCode);
    } else {
      return undefined;
    }
  }, [accessCode, firebaseUser]);
  const [tourRedemption, tourRedemptionLoaded] = useFirestoreDocument(
    () => tourRedemptionDoc,
    [tourRedemptionDoc]
  );

  // Load the tour information
  const [tour, tourLoaded] = useFirestoreDocument(() => {
    if (tourRedemption) {
      return tourDocumentById(tourRedemption.tourId);
    } else {
      return undefined;
    }
  }, [tourRedemption]);

  // Load the tour stops
  const [tourStops, tourStopsLoaded] = useFirestoreQuery(() => {
    if (tourRedemption) {
      return tourStopsQuery(tourRedemption.tourId);
    } else {
      return undefined;
    }
  }, [tourRedemption]);

  // Keep track of the current stop id and set a default when loading
  const [currentStopId, setCurrentStopId] = useState<string | undefined>(
    tourRedemption?.currentTourStopId
  );
  useEffect(() => {
    // Ignore if we have a stop id already set
    if (currentStopId) {
      return;
    }

    // Only carry on if both the tour and tour redemption have loaded
    if (tourStops.length === 0 || !tourRedemption) {
      return;
    }

    // If there is a tour redemption stop id, use that. If not, then use the first tour stop id
    setCurrentStopId(tourRedemption.currentTourStopId || tourStops[0].id);
  }, [currentStopId, tourRedemption, tourStops]);

  /**
   * Handle state changes
   */

  // Keep track of the tour redemption expiry time and redirect to the homepage when the tour expires
  useEffect(() => {
    const expiresAt = convertTimestampToDate(tourRedemption?.expiresAt);
    if (!expiresAt) {
      return;
    }

    const now = new Date();

    if (isDateBefore(expiresAt, now)) {
      history.push("/");
      return;
    }

    const differenceMs = differenceInMilliseconds(expiresAt, now);
    const handle = setTimeout(() => {
      history.push("/");
    }, differenceMs);
    return () => {
      clearTimeout(handle);
    };
  }, [history, tourRedemption]);

  // Update the nav bar when the tour details load
  useEffect(() => {
    if (tourRedemption?.expiresAt) {
      setTourExpiresAt(convertTimestampToDate(tourRedemption.expiresAt));
      setOverviewButtonShown(true);
    } else {
      setTourExpiresAt(undefined);
      setOverviewButtonShown(false);
    }
  }, [setOverviewButtonShown, setTourExpiresAt, tourRedemption]);

  // Remove details from the nav bar when the user navigates away from this page
  useEffect(() => {
    return () => {
      setTourExpiresAt(undefined);
      setOverviewButtonShown(false);
    };
  }, [setOverviewButtonShown, setTourExpiresAt]);

  // Handle the start button being pressed
  const [starting, setStarting] = useState(false);
  const onStartButtonPress = useCallback(async () => {
    if (!tourRedemptionDoc || !tour) {
      return;
    }

    try {
      setStarting(true);

      const startedAt = new Date();
      const expiresAt = addHours(startedAt, tour.timeLimitHours);

      await updateFirestoreDocument(tourRedemptionDoc, {
        startedAt,
        expiresAt,
      });
    } catch (e) {
      alert.error(t("startTour.error", { message: e.message }));
    } finally {
      setStarting(false);
    }
  }, [alert, t, tour, tourRedemptionDoc]);

  // Handle a stop being pressed
  const onStopPress = useCallback(
    async (tourStopId: string) => {
      if (!tourRedemptionDoc) {
        return;
      }

      try {
        setShowTourOverview(false);
        setCurrentStopId(tourStopId);
        await updateFirestoreDocument(tourRedemptionDoc, {
          currentTourStopId: tourStopId,
        });
      } catch (e) {
        // Ignore errors
      }
    },
    [setShowTourOverview, tourRedemptionDoc]
  );

  /**
   * Rendering
   */

  // Show a loading message when data hasn't yet loaded
  if (
    !tourRedemptionLoaded ||
    !tourLoaded ||
    !tourStopsLoaded ||
    !currentStopId
  ) {
    return <p>{t("common.loading")}</p>;
  }

  // Redirect to the homepage on invalid data
  if (!isSignedIn || !tourRedemption || !tour || tourStops.length === 0) {
    return <Redirect to="/" />;
  }

  // If we've not yet started, then show the start button screen
  if (!tourRedemption.expiresAt) {
    return (
      <StartTour
        timeLimitHours={tour.timeLimitHours}
        onStartButtonPress={onStartButtonPress}
        loading={starting}
      />
    );
  }

  // Render the tour content
  return (
    <ViewTour
      tourStops={tourStops}
      currentTourStopId={currentStopId}
      onStopPress={onStopPress}
    />
  );
};

export default RedeemedTour;
