import React from "react";

export const NavBarContext = React.createContext<{
  overviewButtonShown: boolean;
  setOverviewButtonShown: (
    shown: boolean | ((prev: boolean) => boolean)
  ) => void;
  showTourOverview: boolean;
  setShowTourOverview: (shown: boolean | ((prev: boolean) => boolean)) => void;
  tourExpiresAt: Date | undefined;
  setTourExpiresAt: (date: Date | undefined) => void;
}>({
  overviewButtonShown: false,
  setOverviewButtonShown: () => {
    throw new Error("Must be called from inside an NavBarContext provider");
  },
  showTourOverview: false,
  setShowTourOverview: () => {
    throw new Error("Must be called from inside an NavBarContext provider");
  },
  tourExpiresAt: undefined,
  setTourExpiresAt: () => {
    throw new Error("Must be called from inside an NavBarContext provider");
  },
});
