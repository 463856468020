export type WardFieldValue = { isEqual: (other: WardFieldValue) => boolean };
export type WardTimestamp = {seconds: number, nanoseconds: number, toDate: ()=>Date, isEqual: (other: WardTimestamp)=>boolean, toMillis: ()=>number, valueOf: ()=>string};
export function isTimestamp(v: any): v is WardTimestamp { return !!v && (typeof v=='object') && !!v.toDate && !!v.toMillis && (typeof v.nanoseconds=='number') && (typeof v.seconds=='number')};
export type WardGeoPoint = { latitude: number, longitude: number, isEqual: (other: WardGeoPoint)=>boolean, toJSON: ()=>{latitude: number, longitude: number} }
export function isGeoPoint(v: any): v is WardGeoPoint {  return !!v && (typeof v=='object') && (typeof v.isEqual=='function')  && (typeof v.latitude=='number') && (typeof v.longitude=='number') };
export type FirewardOutput = /** what you get from DB */ { timestamp: WardTimestamp|null; number: number; };
export type FirewardInput = /** what you send to DB */ { timestamp: WardTimestamp|Date|WardFieldValue; number: number|WardFieldValue; };
export type FirewardTypes = FirewardInput | FirewardOutput;




export type Tour<Types extends FirewardTypes = FirewardTypes> = {
  order: Types['number']
  name: string
  description?: string
  storeId: string
  storeUrl?: string
  iconUrl?: string
  timeLimitHours: Types['number']
}

export type TourStop<Types extends FirewardTypes = FirewardTypes> = {
  order: Types['number']
  name: string
  description: string
  iconUrl?: string
  instagramUrl?: string
  videoUrl: string
  location?: WardGeoPoint
  directionsType: "WALKING" | "DRIVING" | "BICYCLING"
  videoOnly?: boolean
}

export type TuckUser<Types extends FirewardTypes = FirewardTypes> = {
  email: string
}

export type TourRedemption<Types extends FirewardTypes = FirewardTypes> = {
  userId: string
  tourId: string
  accessCode: string
  redeemedAt: Types['timestamp']
  startedAt?: Types['timestamp']
  expiresAt?: Types['timestamp']
  currentTourStopId?: string
}
