import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { WithId } from "../types";
import { onDocumentSnapshotWithId, onDocumentsSnapshotWithId } from "./helpers";

export const useFirestoreQuery = <
  T extends firebase.firestore.DocumentData = firebase.firestore.DocumentData
>(
  queryCreator: () => firebase.firestore.Query<T> | undefined,
  deps: ReadonlyArray<unknown>
): [WithId<T>[], boolean] => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState<WithId<T>[]>([]);

  useEffect(() => {
    const query = queryCreator();
    if (!query) {
      setLoaded(false);
      setList([]);
      return;
    }

    const unsubscribe = onDocumentsSnapshotWithId(query, (docs) => {
      setList(docs);
      setLoaded(true);
    });

    return unsubscribe;
    // This rules does not work with the deps array pattern, so we ignore it here.
    // Be very careful if you add any more parameters to this hook!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [list, loaded];
};

export const useFirestoreDocument = <
  T extends firebase.firestore.DocumentData = firebase.firestore.DocumentData
>(
  documentReferenceCreator: () =>
    | firebase.firestore.DocumentReference<T>
    | undefined,
  deps: ReadonlyArray<unknown>
): [WithId<T> | undefined, boolean] => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<WithId<T>>();

  useEffect(() => {
    const documentReference = documentReferenceCreator();
    if (!documentReference) {
      setLoaded(false);
      setData(undefined);
      return;
    }

    const unsubscribe = onDocumentSnapshotWithId(
      documentReference,
      (newData) => {
        setData(newData);
        setLoaded(true);
      }
    );

    return unsubscribe;
    // This rules does not work with the deps array pattern, so we ignore it here.
    // Be very careful if you add any more parameters to this hook!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [data, loaded];
};
