import {
  DirectionsRenderer,
  DirectionsRendererProps,
  DirectionsService,
  DirectionsServiceProps,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import envelope from "@turf/envelope";
import { featureCollection, point } from "@turf/helpers";
import { useEffect, useCallback, useMemo, useState } from "react";

import logo from "../../assets/images/logo.svg";
import walking from "../../assets/images/walking.svg";
import { useGeolocation } from "../../data/geolocation";
import { TourStop } from "../../types";
import Map, { MapProps } from "./Map";
import styles from "./TourStopMap.module.scss";

interface TourStopMapProps extends MapProps {
  tourStop: TourStop;
}

interface DirectionsInfo {
  distance: string | undefined;
  duration: string | undefined;
}

const TourStopMap: React.FC<TourStopMapProps> = ({ tourStop, ...rest }) => {
  /**
   * Loading state
   */

  // Keep track of the script loading state so we can know when the `google` namespace is available
  const [loaded, setLoaded] = useState(false);
  const onScriptLoad = useCallback(() => setLoaded(true), []);

  // Keep track of the Google map loading state and reference
  const [map, setMap] = useState<google.maps.Map>();
  const onMapLoad = useCallback(
    (newMap: google.maps.Map) => setMap(newMap),
    []
  );

  /**
   * Geolocation
   */

  const { geolocationPosition } = useGeolocation();
  const [originGeolocationPosition, setOriginGeolocationPosition] =
    useState<GeolocationPosition>();
  useEffect(() => {
    if (!originGeolocationPosition && geolocationPosition) {
      setOriginGeolocationPosition(geolocationPosition);
    }
  }, [geolocationPosition, originGeolocationPosition]);

  /**
   * Location management
   */

  // Convert the tour stop location to something that Google Maps can handle
  const tourStopLocation = useMemo(() => {
    if (tourStop.location) {
      return {
        lat: tourStop.location.latitude,
        lng: tourStop.location.longitude,
      };
    } else {
      return undefined;
    }
  }, [tourStop.location]);

  // Set the initial bounds of the maps
  useEffect(() => {
    if (!loaded || !map || !tourStopLocation) {
      return;
    }

    if (!originGeolocationPosition) {
      map.setCenter(tourStopLocation);
      map.setZoom(15);
      return;
    }

    const features = featureCollection([
      point([tourStopLocation.lng, tourStopLocation.lat]),
      point([
        originGeolocationPosition.coords.longitude,
        originGeolocationPosition.coords.latitude,
      ]),
    ]);
    const polygon = envelope(features);
    if (!polygon.bbox) {
      return;
    }
    const bounds = new google.maps.LatLngBounds(
      { lat: polygon.bbox[1], lng: polygon.bbox[0] },
      { lat: polygon.bbox[3], lng: polygon.bbox[2] }
    );
    map.fitBounds(bounds);
  }, [loaded, map, originGeolocationPosition, tourStopLocation]);

  /**
   * Directions
   */

  const directionOptions = useMemo(():
    | DirectionsServiceProps["options"]
    | undefined => {
    if (!loaded || !originGeolocationPosition || !tourStopLocation) {
      return undefined;
    }

    return {
      origin: {
        lat: originGeolocationPosition.coords.latitude,
        lng: originGeolocationPosition.coords.longitude,
      },
      destination: tourStopLocation,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /// @ts-ignore This is actually correct but Typescript just can't tell from the typing of the library and Fireward
      travelMode: tourStop.directionsType || google.maps.TravelMode.WALKING,
    };
  }, [loaded, originGeolocationPosition, tourStop, tourStopLocation]);

  const [directionsInfo, setDirectionInfo] = useState<DirectionsInfo>();
  const [directionsResultOptions, setDirectionResultOptions] =
    useState<DirectionsRendererProps["options"]>();
  const directionsCallback = useCallback(
    (result: google.maps.DirectionsResult | null) => {
      if (!result) {
        return;
      }

      setDirectionResultOptions({
        directions: result,
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: {
          strokeColor: "#469DC9",
          strokeWeight: 4,
          strokeOpacity: 1,
        },
      });

      if (result.routes.length > 0) {
        const route = result.routes[0];

        if (route.legs.length > 0) {
          const leg = route.legs[0];

          setDirectionInfo({
            distance: leg.distance?.text,
            duration: leg.duration?.text,
          });
        }
      }
    },
    []
  );

  /**
   * Rendering
   */

  return (
    <Map
      onScriptLoad={onScriptLoad}
      onLoad={onMapLoad}
      center={tourStopLocation}
      zoom={15}
      {...rest}
    >
      {loaded && tourStopLocation && (
        <Marker
          position={tourStopLocation}
          icon={{
            url: tourStop.iconUrl || logo,
            scaledSize: new google.maps.Size(24, 24),
          }}
          clickable={false}
        />
      )}

      {directionOptions && (
        <DirectionsService
          options={directionOptions}
          callback={directionsCallback}
        />
      )}
      {directionsResultOptions && (
        <DirectionsRenderer options={directionsResultOptions} />
      )}
      {directionsInfo && (
        <InfoWindow
          position={tourStopLocation}
          /// @ts-ignore
          options={{ pixelOffset: { width: 0, height: -25 } }}
        >
          <div className={styles.infoWindow}>
            <img src={walking} alt="" />
            <p className={styles.distance}>{directionsInfo.distance}</p>
            <p>{directionsInfo.duration}</p>
          </div>
        </InfoWindow>
      )}
    </Map>
  );
};

export default TourStopMap;
