import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./Markdown.module.scss";

export interface MarkdownProps
  extends Omit<React.HTMLAttributes<HTMLParagraphElement>, "children"> {
  children: string;
  source?: string;
  linkTarget?: string;
}

const Markdown = ({
  children,
  linkTarget,
  className,
  ...rest
}: MarkdownProps) => {
  return (
    <div className={`${styles.content} ${className}`} {...rest}>
      <ReactMarkdown linkTarget={linkTarget}>{children}</ReactMarkdown>
    </div>
  );
};

export default Markdown;
