import { FirewardInput, FirewardOutput, TourRedemption } from "../types";
import { firebaseFirestore } from "./core";

export const tourRedemptionDocument = (uid: string, accessCode: string) =>
  firebaseFirestore
    .collection("users")
    .doc(uid)
    .collection<TourRedemption<FirewardInput>>("tourRedemptions")
    .doc(accessCode);

export const tourRedemptionsByTourIdQuery = (uid: string, tourId: string) =>
  firebaseFirestore
    .collection("users")
    .doc(uid)
    .collection<TourRedemption<FirewardOutput>>("tourRedemptions")
    .where("tourId", "==", tourId);
