import firebase from "firebase/app";
import React from "react";
import { TuckUser, WithId } from "../../types";

export const AuthContext = React.createContext<{
  isSignedIn: boolean;
  firebaseUser: firebase.User | undefined;
  tuckUser: WithId<TuckUser> | undefined;
  performSignIn: () => Promise<void>;
  performSignOut: () => void;
}>({
  isSignedIn: false,
  firebaseUser: undefined,
  tuckUser: undefined,
  performSignIn: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
  performSignOut: () => {
    throw new Error("Must be called from inside an AuthContext provider");
  },
});
