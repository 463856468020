import Vimeo from "@u-wave/react-vimeo";
import { motion, AnimatePresence } from "framer-motion";
import { useMemo, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../hooks";
import { TourStop, WithId } from "../../../types";
import { FormButton, Markdown, TourStopMap } from "../../components";
import instagramIcon from "../../../assets/images/instagram.svg";
import styles from "./ViewTourStop.module.scss";

export interface ViewTourStopProps {
  tourStop: WithId<TourStop>;
  onNextPress?: () => void;
}

enum ViewTourStopState {
  Map,
  Video,
}

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const ViewTourStop: React.FC<ViewTourStopProps> = ({
  tourStop,
  onNextPress,
}) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const videoSize = useMemo(
    () => ({ width: windowSize.width, height: windowSize.width / (16 / 9) }),
    [windowSize.width]
  );

  /**
   * Manage moving between the two screen states
   */

  const [currentState, setCurrentState] = useState<ViewTourStopState>(
    !tourStop.videoOnly && tourStop.location
      ? ViewTourStopState.Map
      : ViewTourStopState.Video
  );

  const onViewVideoPress = useCallback(() => {
    setCurrentState(ViewTourStopState.Video);
  }, []);

  const onViewMapPress = useCallback(() => {
    setCurrentState(ViewTourStopState.Map);
  }, []);

  /**
   * Rendering
   */

  return (
    <AnimatePresence initial={false}>
      {currentState === ViewTourStopState.Map && (
        <motion.div
          key="map"
          className={styles.mapContainer}
          {...animationProps}
        >
          <TourStopMap mapContainerClassName={styles.map} tourStop={tourStop} />
          <FormButton
            className={styles.viewVideoButton}
            onClick={onViewVideoPress}
          >
            {t("viewTour.viewVideoButton")}
          </FormButton>
        </motion.div>
      )}
      {currentState === ViewTourStopState.Video && (
        <motion.div
          key="video"
          className={styles.videoContainer}
          {...animationProps}
        >
          <div className={styles.videoContent}>
            <Vimeo
              className={styles.video}
              video={tourStop.videoUrl}
              responsive={true}
              showTitle={false}
              showByline={false}
              showPortrait={false}
              width={videoSize.width}
              height={videoSize.height}
            />
            <div className={styles.videoDetails}>
              <h2 className={styles.name}>{tourStop.name}</h2>
              {!!tourStop.instagramUrl && (
                <a
                  href={tourStop.instagramUrl}
                  target="_blank"
                  className={styles.instagramButton}
                  rel="noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt={t("viewTour.instagramButton")}
                  />
                </a>
              )}
              {!!tourStop.location && (
                <button
                  className={styles.viewMapButton}
                  onClick={onViewMapPress}
                >
                  {t("viewTour.viewMapButton")}
                </button>
              )}
              <Markdown className={styles.description}>
                {tourStop.description}
              </Markdown>
            </div>
          </div>
          {!!onNextPress && (
            <FormButton className={styles.nextStopButton} onClick={onNextPress}>
              {t("viewTour.nextStopButton")}
            </FormButton>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ViewTourStop;
