import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import mapLocation from "../../assets/images/mapLocation.svg";
import { useGeolocation } from "../../data/geolocation";
import MapControl from "./MapControl";
import styles from "./Map.module.scss";

export interface MapProps extends GoogleMapProps {
  onScriptLoad?: () => void;
}

const Map: React.FC<MapProps> = ({
  onScriptLoad,
  options,
  children,
  onLoad,
  ...rest
}) => {
  const { t } = useTranslation();

  /**
   * Loading state
   */

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBKPCk8E2QKQu17w4ldDz7HiOfBTKU9SOU",
    version: "beta",
  });
  const calledOnScriptLoad = useRef(false);
  useEffect(() => {
    if (isLoaded && !calledOnScriptLoad.current) {
      calledOnScriptLoad.current = true;
      onScriptLoad?.();
    }
  }, [isLoaded, onScriptLoad]);

  const [map, setMap] = useState<google.maps.Map>();
  const onMapLoad = useCallback(
    (newMap: google.maps.Map) => {
      setMap(newMap);
      onLoad?.(newMap);
    },
    [onLoad]
  );

  /**
   * Options
   */

  const combinedOptions = useMemo(
    () => ({
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
      gestureHandling: "greedy",
      mapId: "7e29e373a6f80f83",
      ...options,
    }),
    [options]
  );

  /**
   * Geolocation
   */

  const {
    isGeolocationAvailable,
    requestGeolocationPermission,
    geolocationPosition,
  } = useGeolocation();

  const location = useMemo(() => {
    if (!geolocationPosition) {
      return undefined;
    }

    return {
      lat: geolocationPosition.coords.latitude,
      lng: geolocationPosition.coords.longitude,
    };
  }, [geolocationPosition]);

  const onLocationPress = useCallback(() => {
    if (location) {
      map?.setCenter(location);
      map?.setZoom(18);
    } else {
      requestGeolocationPermission();
    }
  }, [location, map, requestGeolocationPermission]);

  /**
   * Rendering
   */

  if (!isLoaded) {
    return <p>{t("common.loading")}</p>;
  }

  if (loadError) {
    return <p>{t("map.mapLoadError", { message: loadError.message })}</p>;
  }

  return (
    <GoogleMap options={combinedOptions} onLoad={onMapLoad} {...rest}>
      {isGeolocationAvailable && (
        <>
          <MapControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
            <button className={styles.controlButton} onClick={onLocationPress}>
              <FontAwesomeIcon
                icon={faLocationArrow}
                className={location ? styles.locationShown : undefined}
              />
            </button>
          </MapControl>

          {!!location && (
            <Marker
              position={location}
              icon={{
                url: mapLocation,
                anchor: new google.maps.Point(10, 10),
              }}
              clickable={false}
            />
          )}
        </>
      )}
      {children}
    </GoogleMap>
  );
};

export default Map;
