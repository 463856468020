import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styles from "./App.module.css";
import NavBar from "./NavBar";
import NotFound from "./screens/NotFound";
import Profile from "./screens/Profile";
import Redeem from "./screens/Redeem";
import RedeemedTour from "./screens/RedeemedTour";
import TourDetails from "./screens/TourDetails";
import ToursList from "./screens/ToursList";

const App: React.FC = () => {
  return (
    <Router>
      <div className={styles.container}>
        <div className={styles.header}>
          <NavBar />
        </div>

        <div className={styles.content}>
          <Switch>
            <Route path="/redeemed/:accessCode" exact>
              <RedeemedTour />
            </Route>
            <Route path="/tour/:tourId/redeem" exact>
              <Redeem />
            </Route>
            <Route path="/tour/:tourId" exact>
              <TourDetails />
            </Route>
            <Route path="/profile" exact>
              <Profile />
            </Route>
            <Route path="/" exact>
              <ToursList />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
