import React from "react";

export const GeolocationContext = React.createContext<{
  isGeolocationAvailable: boolean;
  geolocationPosition: GeolocationPosition | undefined;
  requestGeolocationPermission: () => void;
}>({
  isGeolocationAvailable: false,
  geolocationPosition: undefined,
  requestGeolocationPermission: () => {
    throw new Error(
      "Must be called from inside an GeolocationContext provider"
    );
  },
});
