import icon from "./images/icon.svg";
import map1 from "./images/map1.svg";
import map2 from "./images/map2.svg";
import map3 from "./images/map3.svg";
import map4 from "./images/map4.svg";
import map5 from "./images/map5.svg";
import map6 from "./images/map6.svg";
import map7 from "./images/map7.svg";
import map8 from "./images/map8.svg";
import map9 from "./images/map9.svg";
import map10 from "./images/map10.svg";

export const mapIcon = (index: number) => {
  switch (index) {
    case 1:
      return map1;
    case 2:
      return map2;
    case 3:
      return map3;
    case 4:
      return map4;
    case 5:
      return map5;
    case 6:
      return map6;
    case 7:
      return map7;
    case 8:
      return map8;
    case 9:
      return map9;
    case 10:
      return map10;
    default:
      return icon;
  }
};
