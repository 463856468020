import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AlertTemplateProps } from "react-alert";
import styles from "./Alert.module.scss";

const Alert: React.FC<AlertTemplateProps> = ({ message, style, close }) => {
  return (
    <div className={styles.container}>
      <div style={style} className={styles.content}>
        <span className={styles.message}>{message}</span>
        <button onClick={close} className={styles.button}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};

export default Alert;
