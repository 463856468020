import { useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import logo from "../../../assets/images/logo.svg";
import { GeolocationProvider } from "../../../data/geolocation";
import { useNavBar } from "../../../data/navBar";
import { TourStop, WithId } from "../../../types";
import { TourOverviewMap } from "../../components";
import styles from "./ViewTour.module.scss";
import ViewTourStop from "./ViewTourStop";

export interface ViewTourProps {
  tourStops: WithId<TourStop>[];
  currentTourStopId: string | undefined;
  onStopPress: (tourStopId: string) => void;
}

const ViewTour: React.FC<ViewTourProps> = ({
  tourStops,
  currentTourStopId,
  onStopPress,
}) => {
  const { t } = useTranslation();
  const { showTourOverview, setShowTourOverview } = useNavBar();

  const tourStopsButtonRefs = useMemo((): {
    [tourStopId: string]: HTMLButtonElement | null;
  } => {
    return {};
  }, []);

  useEffect(() => {
    if (!currentTourStopId) {
      return;
    }

    window.scrollTo({ top: 0, left: 0 });

    const el = tourStopsButtonRefs[currentTourStopId];
    if (!el) {
      return;
    }

    el.scrollIntoView({ behavior: "smooth", inline: "center" });
  }, [currentTourStopId, tourStopsButtonRefs]);

  const [currentTourStop, nextStopId] = useMemo((): [
    WithId<TourStop> | undefined,
    string | undefined
  ] => {
    if (!currentTourStopId) {
      return [undefined, undefined];
    }

    const index = tourStops.findIndex(
      (tourStop) => tourStop.id === currentTourStopId
    );
    if (index === -1) {
      return [undefined, undefined];
    }

    return [
      tourStops[index],
      index < tourStops.length - 1 ? tourStops[index + 1].id : undefined,
    ];
  }, [currentTourStopId, tourStops]);

  const onCloseOverviewPress = useCallback(() => {
    setShowTourOverview(false);
  }, [setShowTourOverview]);

  return (
    <GeolocationProvider>
      <div className={styles.container}>
        <div className={styles.tourStops}>
          {tourStops.map((tourStop) => (
            <button
              key={tourStop.id}
              className={styles.tourStopContainer}
              onClick={() => onStopPress(tourStop.id)}
              ref={(ref) => {
                tourStopsButtonRefs[tourStop.id] = ref;
              }}
            >
              <img src={tourStop.iconUrl || logo} alt="" />
              <h3
                className={
                  currentTourStopId === tourStop.id
                    ? styles.selected
                    : undefined
                }
              >
                {tourStop.name}
              </h3>
            </button>
          ))}
        </div>
        <div className={styles.stopContent}>
          {!!currentTourStop && (
            <ViewTourStop
              key={currentTourStop.id}
              tourStop={currentTourStop}
              onNextPress={
                nextStopId ? () => onStopPress(nextStopId) : undefined
              }
            />
          )}
        </div>

        <div
          className={`${styles.overviewContainer} ${
            showTourOverview ? styles.overviewContainerActive : ""
          }`}
        >
          <TourOverviewMap
            tourStops={tourStops}
            mapContainerClassName={styles.overviewMap}
            onStopPress={onStopPress}
          />
          <button
            className={styles.closeOverviewButton}
            onClick={onCloseOverviewPress}
          >
            {t("viewTour.closeOverviewButton")}
          </button>
        </div>
      </div>
    </GeolocationProvider>
  );
};

export default ViewTour;
