/* eslint-disable react-hooks/rules-of-hooks */

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";
import "firebase/storage";

// Initialize the Firebase settings
const firebaseApp = firebase.initializeApp({
  projectId: "tuckfoodtours-13507",
  appId: "1:594335793248:web:365e7c866911b87a938305",
  storageBucket: "tuckfoodtours-13507.appspot.com",
  apiKey: "AIzaSyDq5DdJf49wyXugC-GMZg4SZbRdZiNqn50",
  authDomain: "tuckfoodtours-13507.firebaseapp.com",
  messagingSenderId: "594335793248",
  measurementId: "G-NRGZWRNS46",
});

const firebaseAnalytics = firebase.analytics();
const firebaseAuth = firebase.auth();
const firebaseFirestore = firebase.firestore();
const firebaseFunctions = firebase.app().functions("europe-west1");
const firebasePerformance = firebase.performance();
const firebaseStorage = firebase.storage();

// If we are running in development, then setup the Firebase emulator
if (process.env.NODE_ENV === "development") {
  let emulatorHost = "localhost";
  if (window.location.hostname.startsWith("192.")) {
    console.log("window.location.hostname", window.location.hostname);
    emulatorHost = window.location.hostname;
  }

  firebaseAuth.useEmulator(`http://${emulatorHost}:9099/`);
  firebaseFirestore.useEmulator(emulatorHost, 8080);
  firebaseFunctions.useEmulator(emulatorHost, 5001);
  firebaseStorage.useEmulator(emulatorHost, 9199);
}

// Export all the Firebase SDK parts to use in the rest of the code
export {
  firebaseApp,
  firebaseAnalytics,
  firebaseAuth,
  firebaseFirestore,
  firebaseFunctions,
  firebasePerformance,
  firebaseStorage,
};
