import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styles from "./Button.module.scss";

export interface FormButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const FormButton: React.FC<FormButtonProps> = ({
  loading,
  className,
  children,
  disabled,
  ...rest
}) => {
  const props = {
    className: `${styles.link} ${disabled ? styles.disabled : ""} ${className}`,
    disabled,
    ...rest,
  };

  return (
    <button {...props}>
      {loading ? <FontAwesomeIcon icon={faSpinner} pulse={true} /> : children}
    </button>
  );
};

export default FormButton;
