import { Marker } from "@react-google-maps/api";
import envelope from "@turf/envelope";
import { featureCollection, point } from "@turf/helpers";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { mapIcon } from "../../assets/mapIcon";
import { useGeolocation } from "../../data/geolocation";
import { useNavBar } from "../../data/navBar";
import { TourStop, WithId } from "../../types";
import Map, { MapProps } from "./Map";

export interface TourOverviewMapProps extends MapProps {
  tourStops: WithId<TourStop>[];
  onStopPress: (tourStopId: string) => void;
}

const TourOverviewMap: React.FC<TourOverviewMapProps> = ({
  tourStops,
  onStopPress,
  ...rest
}) => {
  /**
   * Loading state
   */

  // Keep track of the script loading state so we can know when the `google` namespace is available
  const [loaded, setLoaded] = useState(false);
  const onScriptLoad = useCallback(() => setLoaded(true), []);

  // Keep track of the Google map loading state and reference
  const [map, setMap] = useState<google.maps.Map>();
  const onMapLoad = useCallback(
    (newMap: google.maps.Map) => setMap(newMap),
    []
  );

  // Keep track of our own visible state so we can set the initial position whenever the overview opens
  const { showTourOverview } = useNavBar();

  /**
   * Geolocation
   */

  const { geolocationPosition } = useGeolocation();
  const [originGeolocationPosition, setOriginGeolocationPosition] =
    useState<GeolocationPosition>();
  useEffect(() => {
    if (!originGeolocationPosition && geolocationPosition) {
      setOriginGeolocationPosition(geolocationPosition);
    }
  }, [geolocationPosition, originGeolocationPosition]);

  // Set the initial bounds of the maps
  useEffect(() => {
    if (!loaded || !map || !showTourOverview) {
      return;
    }

    const features = featureCollection(
      _.compact([
        ...tourStops.map((tourStop) => {
          if (tourStop.location) {
            return point([
              tourStop.location.longitude,
              tourStop.location.latitude,
            ]);
          } else {
            return undefined;
          }
        }),
        originGeolocationPosition
          ? point([
              originGeolocationPosition.coords.longitude,
              originGeolocationPosition.coords.latitude,
            ])
          : undefined,
      ])
    );
    const polygon = envelope(features);
    if (!polygon.bbox) {
      return;
    }
    const bounds = new google.maps.LatLngBounds(
      { lat: polygon.bbox[1], lng: polygon.bbox[0] },
      { lat: polygon.bbox[3], lng: polygon.bbox[2] }
    );
    map.fitBounds(bounds);
  }, [loaded, map, originGeolocationPosition, showTourOverview, tourStops]);

  /**
   * Render
   */

  return (
    <Map onScriptLoad={onScriptLoad} onLoad={onMapLoad} {...rest}>
      {loaded &&
        tourStops.map(
          (tourStop, index) =>
            tourStop.location && (
              <Marker
                key={tourStop.id}
                position={{
                  lat: tourStop.location.latitude,
                  lng: tourStop.location.longitude,
                }}
                icon={{
                  url: mapIcon(index),
                  scaledSize: new google.maps.Size(24, 24),
                }}
                onClick={() => onStopPress(tourStop.id)}
              />
            )
        )}
    </Map>
  );
};

export default TourOverviewMap;
