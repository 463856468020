import React from "react";
import styles from "./FormInput.module.scss";

type FormInputProps = React.InputHTMLAttributes<HTMLInputElement>;

const FormInput: React.FC<FormInputProps> = ({ className, ...rest }) => {
  const props = {
    className: `${styles.input} ${className}`,
    ...rest,
  };

  return <input {...props} />;
};

export default FormInput;
