/* eslint-disable import/no-named-as-default-member */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HTTPBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const languageDetector = new LanguageDetector();

i18n
  .use(HTTPBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-cache",
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
