import React from "react";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { createRoot } from "react-dom/client";
import "./i18n";
import "./index.scss";
import App from "./ui/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AuthProvider } from "./data/auth";
import { NavBarProvider } from "./data/navBar";
import { Alert } from "./ui/components";
import Loading from "./ui/screens/Loading";

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <AuthProvider>
        <NavBarProvider>
          <AlertProvider
            position={positions.BOTTOM_CENTER}
            timeout={10000}
            transition={transitions.FADE}
            template={Alert}
          >
            <App />
          </AlertProvider>
        </NavBarProvider>
      </AuthProvider>
    </React.Suspense>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
