import { useTranslation } from "react-i18next";
import styles from "./NotFound.module.scss";

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h2>{t("notFound.message")}</h2>
    </div>
  );
};

export default NotFound;
