import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";
import styles from "./BackLink.module.scss";

const BackLink: React.FC<LinkProps> = ({ className, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Link className={`${styles.link} ${className}`} {...rest}>
      <FontAwesomeIcon icon={faArrowLeft} title={t("common.back")} />
    </Link>
  );
};

export default BackLink;
