import { Redirect, useParams } from "react-router-dom";
import isDateAfter from "date-fns/isAfter";
import styles from "./TourDetails.module.scss";
import logo from "../../assets/images/logo.svg";
import {
  convertTimestampToDate,
  tourDocumentById,
  useFirestoreDocument,
  useFirestoreQuery,
} from "../../firebase";
import { BackLink, ButtonLink } from "../components";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../data/auth";
import { tourRedemptionsByTourIdQuery } from "../../firebase/tourRedemptions";
import { useMemo } from "react";

const TourDetails: React.FC = () => {
  const { t } = useTranslation();
  const { firebaseUser } = useAuth();
  const { tourId } = useParams<{ tourId: string }>();

  /**
   * Load data
   */

  const [tour, loaded] = useFirestoreDocument(
    () => tourDocumentById(tourId),
    [tourId]
  );

  // Load an tour redemptions we have for this tour id and filter that down to the active one if there is one
  const [tourRedemptions] = useFirestoreQuery(() => {
    if (firebaseUser) {
      return tourRedemptionsByTourIdQuery(firebaseUser.uid, tourId);
    } else {
      return undefined;
    }
  }, [firebaseUser, tourId]);
  const activeTourRedemption = useMemo(() => {
    const now = new Date();
    return tourRedemptions.find((tourRedemption) => {
      // Return the previous reduce value if we've had a previous success or we have no expiry date
      const expiresAtDate = convertTimestampToDate(tourRedemption.expiresAt);
      if (!expiresAtDate) {
        return false;
      }
      // Return true if the tour has not expired
      return isDateAfter(expiresAtDate, now);
    }, false);
  }, [tourRedemptions]);

  /**
   * Rendering
   */

  if (!loaded) {
    return null;
  }

  if (!tour) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BackLink to="/" />
        <img src={tour.iconUrl || logo} className={styles.icon} alt="" />
        <h2 className={styles.name}>{tour.name}</h2>

        {!!tour.description && (
          <p className={styles.description}>{tour.description}</p>
        )}

        <div className={styles.buttons}>
          {!!activeTourRedemption && (
            <ButtonLink
              to={`/redeemed/${activeTourRedemption.accessCode}`}
              className={styles.button}
              text={t("tourDetails.continueButton")}
            />
          )}

          <ButtonLink
            to={`/tour/${tourId}/redeem`}
            className={styles.button}
            text={t("tourDetails.redeemButton")}
          />

          {!!tour.storeUrl && (
            <ButtonLink
              href={tour.storeUrl}
              className={styles.button}
              text={t("tourDetails.buyButton")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
