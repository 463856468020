import React, { memo, useEffect, useState, useCallback } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { GeolocationContext } from "./GeolocationContext";

const isGeolocationAvailable = "geolocation" in navigator;

export const GeolocationProvider: React.FC<{
  children?: React.ReactNode | undefined;
}> = memo(({ children }) => {
  const { t } = useTranslation();
  const alert = useAlert();

  // Keep track of the permission status and current position
  const [attemptPermission, setAttemptPermission] = useState(false);
  const [geolocationPosition, setGeolocationPosition] =
    useState<GeolocationPosition>();

  // When the component loads, check the status of the geolocation permission
  useEffect(() => {
    // Nothing to do if not supported
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        setAttemptPermission(result.state === "granted");
      });
    }
  }, []);

  // When we have permission, start watching for location changes
  useEffect(() => {
    if (!isGeolocationAvailable) {
      return;
    }

    if (!attemptPermission) {
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setGeolocationPosition(position);
      },
      (error) => {
        if (error.code === 1) {
          alert.error(t("map.locationPermissionError"));
        } else {
          alert.error(t("map.locationError"));
        }

        setAttemptPermission(false);
      },
      { enableHighAccuracy: true }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, [alert, attemptPermission, t]);

  // Request permission by requesting the current location
  const requestGeolocationPermission = useCallback(() => {
    setAttemptPermission(true);
  }, []);

  return (
    <GeolocationContext.Provider
      value={{
        isGeolocationAvailable,
        geolocationPosition,
        requestGeolocationPermission,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
});
