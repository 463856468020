import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./Button.module.scss";

interface ButtonLinkProps extends Omit<LinkProps, "to"> {
  to?: string;
  text: string;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  to,
  text,
  className,
  ...rest
}) => {
  const props = {
    className: `${styles.link} ${className}`,
    ...rest,
  };

  if (to) {
    return (
      <Link to={to} {...props}>
        {text}
      </Link>
    );
  } else {
    return (
      <a target="_blank" {...props}>
        {text}
      </a>
    );
  }
};

export default ButtonLink;
